import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { Product, ProductCollection } from "@planetadeleste/vue-mc-shopaholic";
import type { Route } from "vue-router";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Product, ProductCollection>
>;

@Component
export default class ProductsMixin extends Mixins(TypedModelMixin) {
  obCollection = new ProductCollection();
  obModelClass = Product;
  obCollectionClass = ProductCollection;
  sRouteName = "products.list";
  arOfferRoutes: string[] = [
    "products.update.offer.update",
    "products.update.offer.create",
    "products.create.offer.create",
  ];

  arProductRoutes: string[] = ["products.update", "products.create"];

  created() {
    this.onCreated();
  }

  onRouteChanged(to: Route, from?: Route): void {
    if (to.name) {
      if (this.arProductRoutes.includes(to.name)) {
        // If come from offer route does not reload model
        if (from && from.name && this.arOfferRoutes.includes(from.name)) {
          return;
        }

        this.onUpdateOrCreate(Number(to.params.id));
        return;
      }

      if (this.arOfferRoutes.includes(to.name)) {
        this.displayForm = true;
        return;
      }
    }

    this.displayForm = false;
  }
}
