<template>
  <sheet-filters
    v-model="obFilterData"
    :persistent="persistent"
    :model-name="modelClassName"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('active')"
            :value="1"
            v-model="obFilterData.active"
            @change="apply"
          ></v-switch>
        </v-col>
        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('product.for.sales')"
            :value="1"
            v-model="obFilterData.bySell"
            @change="apply"
          ></v-switch>
        </v-col>
        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('product.for.service')"
            :value="1"
            v-model="obFilterData.byService"
            @change="apply"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4">
          <search-field
            v-model="obFilterData.search"
            clearable
            persistent-hint
            :hint="$t('product.filters.search.hint')"
            @save="apply"
          />
        </v-col>

        <v-col cols="12" md="4" lg="3">
          <categories-select v-model="obFilterData.category" @change="apply" />
        </v-col>

        <v-col cols="12" md="4" lg="3">
          <price-list-select v-model="obFilterData.pricelist" @change="apply" />
        </v-col>
      </v-row>
      <!-- <loading-overlay :loading="loading" local /> -->
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import ProductsMixin from "@/modules/products/mixins/ProductsMixin";

import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import LoadingOverlay from "@/components/common/loading/index.vue";
import CategoriesSelect from "@/modules/categories/components/CategoriesSelect.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import { AppModule } from "@/store/app";

@Component({
  components: {
    SheetFilters,
    SearchField,
    LoadingOverlay,
    CategoriesSelect,
    PriceListSelect,
  },
})
export default class ProductFilters extends Mixins(ProductsMixin) {
  // @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly persistent!: boolean;

  obFilterData: Record<string, any> = {};

  onMounted() {
    this.obFilterData = this.$_.get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    this.applyFilters();
  }
}
</script>
